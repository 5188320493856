* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus {
  outline: .1rem solid #fc3969;
  box-shadow: 0 0 .8rem #fc396966;
}

html {
  font-size: 10px;
}

body {
  font-family: sans-serif;
}

button {
  background-color: #0000;
  border: 0;
  padding: 0;
}

.table {
  background: #fff;
  border: .1rem solid #11223e0d;
  border-radius: .5rem;
  flex-direction: column;
  margin: 2rem 1rem;
  padding: 4rem;
  display: flex;
}

@media (max-width: 500px) {
  .table {
    padding: .5rem;
  }
}

.table__header {
  flex-direction: column;
  gap: 3rem;
  display: flex;
}

.table__header--tools {
  width: 100%;
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.table__header--tools--switch button {
  float: left;
  opacity: .5;
  border-radius: .5rem 0 0 .5rem;
  margin-right: 0;
}

.table__header--tools--switch button:last-child {
  border-left: 0;
  border-radius: 0 .5rem .5rem 0;
}

.table__header--tools--switch button.selected {
  opacity: 1;
}

@media (max-width: 500px) {
  .table__header--tools {
    justify-content: center;
  }
}

.table__header--tools .col {
  min-width: 250px;
  width: fit-content;
  flex: 1;
  gap: 2rem;
  display: flex;
}

@media (max-width: 500px) {
  .table__header--tools .col {
    justify-content: space-between;
    margin: 1rem 0;
  }
}

.table__header--tools .col:last-child {
  justify-content: end;
}

@media (max-width: 500px) {
  .table__header--tools .col:last-child {
    justify-content: space-between;
  }
}

.table__header--tools--search {
  color: #11223e;
  opacity: .7;
  max-width: 24rem;
  width: 40%;
  background: #fff;
  border: .1rem solid #223e691a;
  border-radius: .5rem;
  padding: 1.3rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.1rem;
}

.table__header__status {
  width: 100%;
  background-color: #f8e71c26;
  border-radius: .5rem;
}

.table__header__status .wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: auto;
  padding: 1.1rem;
  display: flex;
}

.table__header__status .wrapper .percentile {
  vertical-align: middle;
  background-color: #fff;
  border-radius: .5rem;
  padding: .5rem;
  position: relative;
  overflow: hidden;
}

.table__header__status .wrapper .percentile .overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #f8e71c;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.table__header__status .wrapper .percentile p {
  color: #11223e;
  z-index: 2;
  font-size: 1.2rem;
  font-weight: 400;
  position: relative;
}

.table__header__status .wrapper .message {
  color: #11223e80;
  font-size: 1.4rem;
  font-weight: 400;
}

.table__header__status .wrapper .message:before {
  content: "!";
  text-align: center;
  border: .1rem solid #11223e80;
  border-radius: 100%;
  margin: 0 1rem 0 0;
  padding: .1rem .7rem;
}

.table__wrapper {
  max-width: 100%;
  overflow: auto;
}

.table__content {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: .5rem;
  margin: 0;
  padding: 0;
}

.table__content .sticky--first {
  min-width: 30rem;
  max-width: 45rem;
  text-align: left;
  z-index: 2;
  border: .1rem solid #11223e33;
  padding: 1.6rem 1.4rem;
  position: sticky;
  left: 0;
}

@media (max-width: 500px) {
  .table__content .sticky--first {
    min-width: 15rem;
  }
}

.table__content .sticky--first i {
  margin-right: 1.5rem;
  font-size: 1.8rem;
}

.table__content .sticky--first .table__checkbox {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.table__content .sticky--first .table__checkbox + label {
  -webkit-user-select: none;
  user-select: none;
}

.table__content .sticky--first .table__checkbox + label:before {
  content: "";
  width: 2rem;
  height: 2rem;
  vertical-align: top;
  background-color: #223e6933;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  border-radius: .5rem;
  margin-right: 2rem;
  transition: all .4s ease-in-out;
  display: inline-block;
}

.table__content .sticky--first .table__checkbox:checked + label:before {
  background-color: #fc3969;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  border-color: #fc3969;
  transition: all .2s ease-in-out;
}

.table__content .sticky--second {
  z-index: 2;
  text-align: center;
  border: .1rem solid #11223e33;
  padding: 1.6rem 1.4rem;
  position: sticky;
  left: 30rem;
}

@media (max-width: 500px) {
  .table__content .sticky--second {
    left: 15rem;
  }
}

.table__content td, .table__content th {
  text-align: center;
  background-color: #fff;
  border: .1rem solid #11223e12;
  padding: 1.6rem 1.4rem;
}

.table__content td {
  color: #11223e;
  font-size: 1.4rem;
  line-height: 1.8rem;
  position: relative;
}

.table__content td .sub {
  width: 50%;
  border-radius: .3rem;
  padding: .2rem .5rem;
  font-size: 1rem;
  line-height: .8rem;
  position: absolute;
  bottom: .5rem;
  left: 25%;
}

.table__content td .pill_green {
  color: #355b08;
  background: #75b9264d;
}

.table__content td .pill_red {
  color: #d0021b;
  background: #d0021b26;
}

.table__content td .sup {
  text-align: right;
  color: #355b08;
  font-size: 1rem;
  line-height: .8rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.table__content td .up {
  color: #355b08;
  font-size: 1rem;
  line-height: .7rem;
}

.table__content td .up:before {
  content: "";
  width: 0;
  height: 0;
  border-bottom: .5rem solid #355b08;
  border-left: .5rem solid #0000;
  border-right: .5rem solid #0000;
  position: absolute;
  top: 25%;
  right: 150%;
}

.table__content td .down {
  color: #d0021b;
  font-size: 1rem;
  line-height: .7rem;
}

.table__content td .down:before {
  content: "";
  width: 0;
  height: 0;
  border-top: .5rem solid #d0021b;
  border-left: .5rem solid #0000;
  border-right: .5rem solid #0000;
  position: absolute;
  top: 25%;
  right: 150%;
}

.table__content td.shade_green {
  background-color: #75b92626 !important;
}

.table__content td.shade_red {
  background-color: #d0021b26 !important;
}

.table__content td .cell__wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.table__content td .cell__wrapper .caption {
  place-items: center start;
  display: flex;
}

.table__content td .icons__column {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.table__content td .icons__column .icon1 {
  width: 2.4rem;
  height: 2.4rem;
  opacity: .7;
  background-image: url("https://s3-alpha-sig.figma.com/img/d445/76ca/25a281427004e022445a24b157372ba0?Expires=1653264000&Signature=b8LIuVriBpG7ivm1mbb6~7N~0P2wdDqLT7ScNM2PSOUbUwC8POJ6m-TKhO92DERrioc8i-sP9381msAznH-ICzyFjB5ES9O-20xhIhcOtukxeIeZezeFEXmdGMylIe1MVWiZvjpBxqoOkWaFf9In8wU5tm3AAGbws1vY6e7L49RlpM-~m-JEULNfYSNYPeeDZXtihBCfYQkgHJsyemF2f-QAq7YANhWBdkGUzvfQItdfoGZQ5ElKUbpvlr~TtFpCrRTpVnP1ebqFDsvXx84iGN-YCrZz~EP4LKuY9yq2z9C-X9JJiuLSBBNZqI6fuaGoN-LV2tmmUfcALIs-Oyrr3w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"), linear-gradient(#fff3, #fff3);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 220%;
  border-radius: .5rem;
  margin: .5rem 0;
  padding: 1.2rem;
}

.table__content td .icons__column .icon1:hover {
  opacity: 1;
}

.table__content td .icons__column .icon2 {
  width: 2.4rem;
  height: 2.4rem;
  opacity: .7;
  background-image: url("https://s3-alpha-sig.figma.com/img/0640/549e/2c8f4b4acd23f4664b5e78de189797ae?Expires=1653264000&Signature=Pi1HfjXFIY1pnLYzTEEKYuxqq~3gMTgmV1inCTd23nek5VD0Z3~eF5fsZYYmCBJeJcXUstJv~SlN6sj8FSy~TeC4Umg2RZYIf0OEzRGhUVLbLNlqKvgRV0Ccrf6FvZjAWx0qVeInzGLdtzt1e~e4WwKiBvaf0~UT3Ilzh9G6K5R1U2edAmby2GYye0aLDgnqAy-nia5pLJVmduwrOErIJ4a3lTcIeWTbsTnyWHnPXaSLZUcHLgRxDQFIzEJab6ysU8yqrvDX7oJUghS31mALlDhhtLIGQO-CoOJ2DA5Sk8SqnhvzPA9wShY-xDzSRO6T85PjniCGralOu2gbJIcdjQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"), linear-gradient(#fff3, #fff3);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 220%;
  border-radius: .5rem;
  margin: .5rem 0;
  padding: 1.2rem;
}

.table__content td .icons__column .icon2:hover {
  opacity: 1;
}

.table__content td .icons__column .icon3 {
  width: 2.4rem;
  height: 2.4rem;
  opacity: .7;
  background-image: url("https://s3-alpha-sig.figma.com/img/b124/9236/08719f66f37795bcf1dad78d3693a565?Expires=1653264000&Signature=PIzYArQEUrVstACcncSIC-K1WMP5eq2Saadt9dEfOi9GohEYnlTXmZQFI9vBzMqagOFb9ysqDKuIo7cAudSQfAj0Vg0lKMpHYFsB54FKBxUdY1h7UMebrNf58lS52uaF2uiBagLt0pbJYg0PFnt9EfI72r-mTNO2xb86O0cdY-0QVRPZesPfRq660vJoMHChiQKl9xwC2q~kNBAz~YiGdqXd426pfauBlX~1nGKTp3CuOht147Nel-zo2WQYtU9qQHcAO22aT2qU10aSpe2QPttdAlCtY~moCyG0QrhcYDDh8P0jKBbZokCerlDWzYJq9Z7rNdAvJsZZ1IasK6tJ0A__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"), linear-gradient(#fff3, #fff3);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 220%;
  border-radius: .5rem;
  margin: .5rem 0;
  padding: 1.2rem;
}

.table__content td .icons__column .icon3:hover {
  opacity: 1;
}

.table__content td .icons {
  text-align: right;
  flex: 1;
  display: inline-flex;
}

.table__content td .fa-star {
  opacity: 0;
  transition: all .3s ease-in-out;
}

.table__content td .fa-star.checked {
  opacity: 1;
  color: #f5a623;
}

.table__content td .fa-angle-right {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  margin: auto 0;
  padding: .5rem;
  font-size: 1.2rem;
  display: block;
}

.table__content td .fa-angle-right:hover {
  background-color: #f3f3f3;
}

.table__content tbody tr:hover td {
  background-color: #f9f9f9;
}

.table__content tbody tr:hover td .fa-star {
  opacity: .2;
  transition: all .15s ease-in-out;
}

.table__content tbody tr:hover td .fa-star:hover {
  color: #f5a623;
  opacity: 1;
  transition: all .15s ease-in-out;
}

.table__content tbody tr:hover td .fa-star.checked {
  opacity: 1;
}

.table__content .date {
  width: 4.5rem;
}

.table__content .additional-row > td.sticky--first, .table__content .additional-row > td.sticky--second, .table__content .additional-row > td {
  border: none;
}

.table__content .additional-row {
  height: 100%;
  opacity: 0;
  line-height: 100%;
  transition: all 1s ease-in-out;
  display: none;
}

.table__content thead {
  height: auto;
  z-index: 2;
  color: #11223e4d;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
  position: sticky;
  top: 0;
}

.button__main, .button__second {
  color: #fff;
  background-color: #fc3969;
  border-radius: .5rem;
  padding: 1.3rem 2rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6rem;
  transition: all .3s;
  box-shadow: 0 .3rem .7rem #fc39694d;
}

.button__main:hover, .button__second:hover {
  transition: all .3s;
  transform: scale(.98);
}

.button__main:active, .button__second:active {
  opacity: .6;
}

.button__second {
  color: #11223e;
  box-shadow: none;
  background-color: #fff;
  border: .1rem solid #223e6933;
}

@media (max-width: 800px) {
  html {
    font-size: 8px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 2200px) {
  html {
    font-size: 22px;
  }
}

@media (min-width: 3500px) {
  html {
    font-size: 33px;
  }
}

/*# sourceMappingURL=index.057028dc.css.map */
