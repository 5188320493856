$mainColor:#FC3969;
$secondColor:#F8E71C;
$accentColor:#75B926;

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    &:focus{
        outline: 0.1rem solid $mainColor;
        box-shadow: 0px 0px 0.8rem rgba($color:$mainColor, $alpha: 0.40);
    }
}

html{
    font-size: 10px;
}

body{
    font-family: sans-serif;
}

button{
    background-color: transparent;
    border: 0;
    padding: 0;
}

.table{
    background: #FFFFFF;
    padding: 4rem;
    margin: 2rem 1rem;
    border: 0.1rem solid rgba(17, 34, 62, 0.05);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    @media (max-width:500px){
        padding: 0.5rem;
    }

    &__header{
        display: flex;
        gap:3rem;
        flex-direction: column;
        &--tools{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            // flex-flow: wrap;
            // min-width: 550px;
            flex-direction: row;
            justify-content: space-between;
            &--switch button{
                float:left;
                margin-right:0 ;
                border-radius: 0.5rem 0 0 0.5rem ;
                opacity: 0.5;
                &:last-child{
                    border-radius:0 0.5rem 0.5rem 0;
                    border-left: 0;
                }
                &.selected{
                    opacity:1
                }

            }
            @media (max-width:500px) {
                justify-content: center;
                
            }
            .col{
                min-width: 250px;
                flex: 1;
                gap: 2rem;
                display: flex;
                width: fit-content;
                @media (max-width:500px) {
                    justify-content: space-between;
                    margin:1rem 0;
                }
                &:last-child{
                    justify-content: end;
                    @media (max-width:500px) {
                        justify-content: space-between;
                        
                    }
                }
            }
            &--search{
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 1.1rem;
                /* identical to box height, or 69% */
                color: #11223E;
                opacity: 0.7;
                padding: 1.3rem 1.6rem;
                background: #FFFFFF;
                border: 0.1rem solid rgba(34, 62, 105, 0.1);
                border-radius: 0.5rem;
                max-width: 24rem;
                width: 40%;
                }
        }
        &__status{
            width: 100%;
            background-color:rgba($color: $secondColor, $alpha: 0.15);
            // opacity: 0.15;
            border-radius: 0.5rem;
            .wrapper{
                padding: 1.1rem;
                display: flex;
                flex-direction: row;
                margin: auto;
                justify-content: center;
                align-items: center;
                gap:2rem;
                .percentile{
                    position: relative;
                    border-radius: 0.5rem;
                    background-color: #FFFFFF;
                    vertical-align: middle;
                    padding: 0.5rem;
                    overflow: hidden;
                    .overlay{
                        position: absolute;
                        display: block;
                        left: 0;
                        top: 0;
                        background-color: #F8E71C;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        // mix-blend-mode: multiply; 
                    }
                    p{
                        color:#11223E;
                        font-weight: 400;
                        font-size: 1.2rem;
                        position: relative;
                        z-index: 2;
                    }
                }
                .message{
                    color:rgba($color:#11223E , $alpha: 0.5);
                    font-size: 1.4rem;
                    font-weight: 400;
                    &::before{
                        content: "!";
                        margin: 0 1rem 0 0;
                        border: 0.1rem solid rgba($color:#11223E , $alpha: 0.5);
                        padding: 0.1rem 0.7rem;
                        border-radius: 100%;
                        // width:100%;
                        // height: 100%;
                        // display: inline-block;
                        text-align: center;
                    }
                }
            }
        }
    }
    &__wrapper{
        overflow: auto;
        max-width: 100%;
        // max-height: 80vh;
    }
    &__content{
        padding: 0;
        margin:0;
        // overflow-x: scroll;
        // min-width: 1600px;
        border-collapse:separate;
        border-spacing:0;
        border-radius: 0.5rem;
        .sticky--first{
            position: sticky;
            position: -webkit-sticky;
            left: 0;
            min-width: 30rem;
            max-width: 45rem;
            border:0.1rem solid rgba($color:#11223E , $alpha: 0.2);
            padding: 1.6rem 1.4rem;
            text-align: left;
            z-index: 2;
            @media (max-width:500px){
                min-width: 15rem;
            }
            i{
                font-size: 1.8rem;
                margin-right:1.5rem;
            }
           
            .table__checkbox{
                position: absolute;
                z-index: -1;
                opacity: 0;
                &+label{
                    // display: inline-flex;
                    // align-items: center;
                    user-select: none;
                    &::before{
                        content: '';
                        display: inline-block;
                        width: 2rem;
                        height: 2rem;
                        border-radius: 0.5rem;
                        background-color: rgba($color: #223E69, $alpha: 0.2);
                        margin-right: 2rem;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 50% 50%;
                        vertical-align:top;
                        transition: all 0.4s ease-in-out;
                        // position: relative;
                    }
                }
                &:checked+label::before{
                    border-color: $mainColor;
                    background-color: $mainColor;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");    
                    transition: all 0.2s ease-in-out;
                }
            }

            
        }
        .sticky--second{
            z-index: 2;
            padding: 1.6rem 1.4rem;
            position: sticky;
            position: -webkit-sticky;
            left:30rem;
            border:0.1rem solid rgba($color:#11223E , $alpha: 0.2);
            text-align: center;
            @media (max-width:500px){
                left: 15rem;
            }
        }
        td,th{
            text-align: center;
            padding: 1.6rem 1.4rem;
            background-color: #FFFFFF;
            border:0.1rem solid rgba($color:#11223E , $alpha: 0.07);
        }
        td{
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: #11223E;
            position: relative;
            .sub{
                position: absolute;
                // z-index: 2;
                font-size: 1rem;
                line-height: 0.8rem;
                width:50%;
                bottom: 0.5rem;
                left:25%;
                // transform: translate(0,100%);
                border-radius: 0.3rem;
                padding: 0.2rem 0.5rem;
            }
            .pill_green{
                color: #355B08; 
                background: rgba($color:#75B926, $alpha:0.3);
            }
            .pill_red{
                color: #D0021B; 
                background: rgba($color:#D0021B, $alpha:0.15);
            }
            .sup{
                position:absolute;
                font-size: 1rem;
                line-height: 0.8rem;
                // width:50%÷;
                text-align: right;
                top: 0.5rem;
                right:0.5rem;
                color: #355B08; 
                
            }
            .up{
                color: #355B08; 
                font-size: 1rem;
                line-height: 0.7rem;
                &::before{
                    content: "";
                    width:0;
                    height:0;
                    border-left: 0.5rem solid transparent;
                    border-right: 0.5rem solid transparent;
                    border-bottom: 0.5rem solid #355B08;
                    position: absolute;
                    right:150%;
                    top:25%;
                }
            }
            .down{
                font-size: 1rem;
                line-height: 0.7rem;
                color: #D0021B;
                &::before{
                    content: "";
                    width:0;
                    height:0;
                    border-left: 0.5rem solid transparent;
                    border-right: 0.5rem solid transparent;
                    border-top: 0.5rem solid #D0021B;
                    position: absolute;
                    right:150%;
                    top:25%;
                }
            }
            &.shade_green{
                background-color: rgba($color: #75B926, $alpha: 0.15) !important;
            }
            &.shade_red{
                background-color: rgba($color: #D0021B, $alpha: 0.15) !important;

            }
            .cell__wrapper{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .caption{
                    display: flex;
                    justify-items: start;
                    align-items: center;
                }
            }
            .icons__column{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items:center;
                .icon1{
                    width:2.4rem;
                    padding: 1.2rem;
                    border-radius: 0.5rem;
                    height:2.4rem;
                    background-size: 220%;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-image: url(https://s3-alpha-sig.figma.com/img/d445/76ca/25a281427004e022445a24b157372ba0?Expires=1653264000&Signature=b8LIuVriBpG7ivm1mbb6~7N~0P2wdDqLT7ScNM2PSOUbUwC8POJ6m-TKhO92DERrioc8i-sP9381msAznH-ICzyFjB5ES9O-20xhIhcOtukxeIeZezeFEXmdGMylIe1MVWiZvjpBxqoOkWaFf9In8wU5tm3AAGbws1vY6e7L49RlpM-~m-JEULNfYSNYPeeDZXtihBCfYQkgHJsyemF2f-QAq7YANhWBdkGUzvfQItdfoGZQ5ElKUbpvlr~TtFpCrRTpVnP1ebqFDsvXx84iGN-YCrZz~EP4LKuY9yq2z9C-X9JJiuLSBBNZqI6fuaGoN-LV2tmmUfcALIs-Oyrr3w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA),linear-gradient(#FFFFFF33,#FFFFFF33);
                    margin:0.5rem 0;
                    opacity: 0.7;
                    &:hover{
                        opacity: 1;
                    }
                }
                .icon2{
                    width:2.4rem;
                    padding: 1.2rem;
                    border-radius: 0.5rem;
                    height:2.4rem;
                    background-size: 220%;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-image: url(https://s3-alpha-sig.figma.com/img/0640/549e/2c8f4b4acd23f4664b5e78de189797ae?Expires=1653264000&Signature=Pi1HfjXFIY1pnLYzTEEKYuxqq~3gMTgmV1inCTd23nek5VD0Z3~eF5fsZYYmCBJeJcXUstJv~SlN6sj8FSy~TeC4Umg2RZYIf0OEzRGhUVLbLNlqKvgRV0Ccrf6FvZjAWx0qVeInzGLdtzt1e~e4WwKiBvaf0~UT3Ilzh9G6K5R1U2edAmby2GYye0aLDgnqAy-nia5pLJVmduwrOErIJ4a3lTcIeWTbsTnyWHnPXaSLZUcHLgRxDQFIzEJab6ysU8yqrvDX7oJUghS31mALlDhhtLIGQO-CoOJ2DA5Sk8SqnhvzPA9wShY-xDzSRO6T85PjniCGralOu2gbJIcdjQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA),linear-gradient(#FFFFFF33,#FFFFFF33);
                    margin:0.5rem 0;
                    opacity: 0.7;
                    &:hover{
                        opacity: 1;
                    }
                }
                .icon3{
                    width:2.4rem;
                    padding: 1.2rem;
                    border-radius: 0.5rem;
                    height:2.4rem;
                    background-size: 220%;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-image: url(https://s3-alpha-sig.figma.com/img/b124/9236/08719f66f37795bcf1dad78d3693a565?Expires=1653264000&Signature=PIzYArQEUrVstACcncSIC-K1WMP5eq2Saadt9dEfOi9GohEYnlTXmZQFI9vBzMqagOFb9ysqDKuIo7cAudSQfAj0Vg0lKMpHYFsB54FKBxUdY1h7UMebrNf58lS52uaF2uiBagLt0pbJYg0PFnt9EfI72r-mTNO2xb86O0cdY-0QVRPZesPfRq660vJoMHChiQKl9xwC2q~kNBAz~YiGdqXd426pfauBlX~1nGKTp3CuOht147Nel-zo2WQYtU9qQHcAO22aT2qU10aSpe2QPttdAlCtY~moCyG0QrhcYDDh8P0jKBbZokCerlDWzYJq9Z7rNdAvJsZZ1IasK6tJ0A__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA),linear-gradient(#FFFFFF33,#FFFFFF33);
                    margin:0.5rem 0;
                    opacity: 0.7;
                    &:hover{
                        opacity: 1;
                    }
                }
            }
            .icons{
                flex:1;
                display: inline-flex;
                text-align: right;
                // margin-left: 0px;
            }
            .fa-star{
                // float: right;
                // color: #FFF;
                // stroke:1px solid #11223E;
                opacity: 0;
                transition: all 0.3s ease-in-out;
                &.checked{
                    opacity: 1;
                    color:#F5A623;
                }
                
            }
            .fa-angle-right{
                display: block;
                width: 2rem;;
                height: 2rem;;
                padding: 0.5rem;
                border-radius: 100%;
                font-size: 1.2rem;
                // vertical-align: middle;
                margin: auto 0;
                &:hover{
                    background-color: #F3F3F3;
                }
            }
        }
        tbody tr:hover td{
            background-color:#F9f9f9;
            .fa-star{
                opacity: 0.2;
                transition: all 0.15s ease-in-out;
                &:hover{
                    color: #F5A623;
                    opacity: 1;
                    transition: all 0.15s ease-in-out;
                }
            }
            .fa-star.checked{
                opacity: 1;
            }
        }
        .date{
            width:4.5rem;
        }
        .additional-row>td.sticky--first, .additional-row>td.sticky--second,.additional-row>td{
            border: none;
        }
        .additional-row{
            height:100%;
            line-height: 100%;
            transition-duration: .75s;
            display: none;
            opacity: 0;
            transition: 1s all ease-in-out;
        }

        thead{
            position: sticky;
            top: 0;
            height:auto;
            z-index: 2;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.5rem;
            /* or 125% */
            color:rgba($color:#11223E , $alpha: 0.3);
        }
    }
}

.button__main{
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.6rem;
    /* identical to box height, or 69% */
    background-color: $mainColor;
    padding: 1.3rem 2rem;
    color: #FFFFFF;
    box-shadow: 0px 0.3rem 0.7rem rgba(252, 57, 105, 0.3);
    border-radius: 0.5rem;
    transition: 0.3s all;
    &:hover{
        transform: scale(0.98);
        transition: 0.3s all;    
    }
    &:active{
        opacity: 0.6;
    }
}

.button__second{
    @extend .button__main;  
    color: #11223E;
    background-color: #FFFFFF;
    border: 0.1rem solid rgba($color: #223E69, $alpha: 0.2);
    box-shadow:none;

}

@media (max-width:800px) {
    
    html{
        font-size: 8px;
    }
    
}
@media (min-width:1600px) {
   
    html{
        font-size: 18px;
    }
    
}

@media (min-width:2200px) {
    
    html{
        font-size: 22px;
    }
    
}
@media (min-width:3500px) {
   
    html{
        font-size: 33px;
    }
    
}

